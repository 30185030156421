import { makeStyles } from 'tss-react/mui';
export var appStyle = makeStyles()(function (theme) {
    var _a;
    return ({
        layout: (_a = {
                marginLeft: 140,
                marginRight: 140,
                marginTop: 30
            },
            _a[theme.breakpoints.down('xl')] = {
                marginLeft: '2%',
                marginRight: '2%',
            },
            _a),
    });
});
