import { PusherConnectionStatus } from './../globals/enums';
import Pusher from 'pusher-js';
/**
 * @class PusherAPI
 * @description class craeted on top of pusher library to handle pusher methods
 */
var PusherAPI = /** @class */ (function () {
    function PusherAPI() {
        this.pusher = {};
        this.connectionStatus = PusherConnectionStatus.Disconnected;
    }
    /**
     * @function pusherConnect
     * @description function to stablish the connection with pusher
     * @param {string} appKey
     * @param {PusherTypes.Options } channelOptions
     */
    PusherAPI.prototype.pusherConnect = function (appKey, channelOptions) {
        this.pusher = new Pusher(appKey, channelOptions);
    };
    /**
     * @function pusherDebug
     * @description Only use in development mode debug mode for pusher
     */
    PusherAPI.prototype.pusherDebug = function () {
        Pusher.logToConsole = true;
    };
    /**
     * @function onPusherStateChange
     * @description get pusher current state
     * @param {callback} pusherSateChangeHandler to get the latest pusher state
     */
    PusherAPI.prototype.onPusherStateChange = function (pusherSateChangeHandler) {
        var _this = this;
        this.pusher.connection.bind('state_change', function (states) {
            // state object :- states = {previous: 'oldState', current: 'newState'}
            // eslint-disable-next-line no-console
            console.log('Current pusher state is', states);
            if (pusherSateChangeHandler) {
                pusherSateChangeHandler(states.current);
            }
            _this.connectionStatus = states.current;
        });
    };
    Object.defineProperty(PusherAPI.prototype, "currentConnectionStatus", {
        /**
         * @description get current connect status of pusher
         * @returns {PusherConnectionStatus} connectionStatus
         */
        get: function () {
            return this.connectionStatus;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @function onError
     * @description get pusher error
     * @param {callback} pusherErrorHandler get pusher error
     */
    PusherAPI.prototype.onError = function (pusherErrorHandler) {
        this.pusher.connection.bind('error', function (err) {
            pusherErrorHandler(err);
        });
    };
    /**
     * @function pusherDisconnect
     * @description function to disconnect the pusher connection
     */
    PusherAPI.prototype.pusherDisconnect = function () {
        if (this.connectionStatus === PusherConnectionStatus.Connected) {
            this.pusher.disconnect();
        }
    };
    PusherAPI.prototype.subscribeChannel = function (channelName) {
        return this.pusher.subscribe(channelName);
    };
    PusherAPI.prototype.unsubscribeChannel = function (channelName) {
        return this.pusher.unsubscribe(channelName);
    };
    return PusherAPI;
}());
export default PusherAPI;
