var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment-timezone';
import { APPLICATION_EVENTS, DATE_FORMAT, TIME_FORMAT, } from 'globals/global.constants';
import { AlarmStatus, EventsType, EventViewState } from 'globals/enums';
import { capitalizeFirstLetter, getAddressByLatLng, isAuthenticateUser, removeObjectProperty, } from 'globals/global.functions';
import { updateAlarmStatusService } from 'services/alarmService/alarm.service';
import { showError } from 'store/commonReducer/common.action';
import get from 'lodash.get';
import { getSeniorMappingService } from 'services/seniorService/senior.service';
export var CREATE_SOS = 'CREATE_SOS';
export var CREATE_FALL_DETECTION = 'CREATE_FALL_DETECTION';
export var CLOSE_ALARM = 'CLOSE_ALARM';
/**
 * @function closeAlarms
 * @description action creator to close the alarms
 */
export var closeAlarms = function (eventId, eventType) {
    return function (dispatch, getState) {
        var events = getState().alarms;
        var updatedAlarm = removeObjectProperty(events[eventType], eventId);
        dispatch({ type: CLOSE_ALARM, payload: { eventType: eventType, updatedAlarm: updatedAlarm } });
    };
};
export var updateAlarmStatus = function (alarmId, status) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var param, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                param = {
                    alarm_id: alarmId,
                    status: status,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, updateAlarmStatusService(param)];
            case 2:
                _a.sent();
                return [2 /*return*/, { success: true }];
            case 3:
                e_1 = _a.sent();
                dispatch(showError(e_1));
                return [2 /*return*/, { success: false }];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var createAlarmDialog = function (data) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var seniorQueryPayload, seniorDetail, seniorName, seniorTimezone, location, seniorAddress, lastKnownAddress, detailList, fullName, alertType, alarmData, eventId, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                seniorQueryPayload = [
                    {
                        user_id: data.seniorId,
                        account_id: data.accountId,
                    },
                ];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, getSeniorMappingService(seniorQueryPayload)];
            case 2:
                seniorDetail = _a.sent();
                seniorName = seniorDetail[data.accountId][data.seniorId]['minimal']['name'];
                seniorTimezone = get(seniorDetail[data.accountId][data.seniorId], 'minimal.timezone');
                location = get(seniorDetail[data.accountId][data.seniorId], 'minimal.location');
                seniorAddress = location
                    ? "".concat(location === null || location === void 0 ? void 0 : location.street, ", ").concat(location === null || location === void 0 ? void 0 : location.city, ", ").concat(location === null || location === void 0 ? void 0 : location.state, ", ").concat(location === null || location === void 0 ? void 0 : location.zipcode)
                    : '-';
                return [4 /*yield*/, getAddressByLatLng(data.lastLocation.lat, data.lastLocation.lng)];
            case 3:
                lastKnownAddress = _a.sent();
                detailList = [
                    {
                        label: 'Date/Time',
                        value: "".concat(moment(data.timestamp).format("".concat(DATE_FORMAT, " @ ").concat(TIME_FORMAT))),
                    },
                    {
                        label: 'Home Address',
                        value: seniorAddress,
                    },
                    {
                        label: 'Last Known Location',
                        value: lastKnownAddress || 'No Address Founds',
                    },
                    {
                        label: "Last ".concat(APPLICATION_EVENTS[data.status].shortLabel, " Occurence"),
                        value: "".concat(data.lastAlertTime
                            ? moment(data.lastAlertTime).format("".concat(DATE_FORMAT, " @ ").concat(TIME_FORMAT))
                            : '-'),
                    },
                ];
                fullName = "".concat(seniorName.first_name, " ").concat(seniorName.last_name);
                alertType = capitalizeFirstLetter(data.status);
                if (!isAuthenticateUser()) {
                    return [2 /*return*/];
                }
                if (data.alarmStatus == AlarmStatus.PENDING) {
                    alarmData = createSosFallData(fullName, data.seniorId, data.status == 'fall' ? EventsType.FALL : EventsType.SOS, EventViewState.Maximize, data.accountId, seniorTimezone, data.alarmId, detailList, data.timestamp, alertType, data.alarmStatus, data.lastAlertTime, data.lastCallTime);
                    if (data.status == 'fall') {
                        dispatch({
                            type: CREATE_FALL_DETECTION,
                            payload: { alarmData: alarmData },
                        });
                    }
                    else if (data.status == 'sos') {
                        dispatch({ type: CREATE_SOS, payload: { alarmData: alarmData } });
                    }
                }
                else if (data.alarmStatus == AlarmStatus.ASSIGNED) {
                    eventId = "".concat(data.seniorId, "-").concat(data.alarmId);
                    dispatch(closeAlarms(eventId, data.status == 'fall' ? EventsType.FALL : EventsType.SOS));
                }
                return [3 /*break*/, 5];
            case 4:
                error_1 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Data is missing in the API', { error: error_1 });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function createSosFallData
 * @description method to create data for alert event
 * @param {string} fullName senior fullname
 * @param {string} seniorId senior ID
 
 * @param {EventsType} type type of event alert
 * @param {EventViewState} viewState view state of event maximize or minimize
 
 */
var createSosFallData = function (fullName, seniorId, eventType, viewState, accountId, seniorTimezone, alarmId, detailList, startTime, alertType, alarmStatus, lastAlertTime, lastCallTime) {
    var _a;
    var eventId = "".concat(seniorId, "-").concat(alarmId);
    return _a = {},
        _a[eventId] = {
            eventType: eventType,
            viewState: viewState,
            fullName: fullName,
            seniorId: seniorId,
            accountId: accountId,
            seniorTimezone: seniorTimezone,
            eventId: eventId,
            alarmId: alarmId,
            detailList: detailList,
            startTime: startTime,
            alertType: alertType,
            alarmStatus: alarmStatus,
            lastAlertTime: lastAlertTime,
            lastCallTime: lastCallTime,
        },
        _a;
};
